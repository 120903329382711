import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { User } from './user.types';
import { ACTIVE_TENANT, LOCALSTORAGE_KEY } from '../../../shared/helpers/constants';

const mockUser = {
  id: '08dccedf-990c-402d-8ad6-47fdfbe31026',
  firstName: 'John',
  lastName: 'Doe',
  email: 'dispatcher@ssessa.com',
  phoneNumber: "233303323203",  
  title: "null",        
  sex: "null",         
  avatar: "null",     
  createdAt: '0001-01-01T00:00:00',
  updatedAt: "null",
  roles: [
    {
      id: '08dccedf-98eb-4d19-8153-c15e723af92b',
      name: 'Dispatcher',
      displayName: 'Dispatcher',
    },
  ],
  tenants: [
    {
      tenantId: 'd97dc83d-45cc-4a53-8c37-f3586e58f81f',
      tenantName: 'SSESSA Truck Trail',
      tenantAvatar: "null",   
      roleName: 'Dispatcher',
      tenantUserStatus: 'Active' as 'Active',
    },
    {
      tenantId: 'd97dc83d-45cc-4a53-8c37-f3586e58ftony',
      tenantName: 'TONNY Truck Trail',
      tenantAvatar: "null",   
      roleName: 'Dispatcher',
      tenantUserStatus: 'Active' as 'Active',
    },
  ],
};



@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly user = signal<User | undefined>(undefined);
  readonly userExists = signal<boolean>(false);
  private readonly baseUrl = environment.baseUrl;
  private readonly http = inject(HttpClient);
 
  public currentTenantId = signal<string>(this.getDefaultTenant()?.tenantId || '');

  constructor() {}


  

  async fetchUser() {
    const token = localStorage.getItem(LOCALSTORAGE_KEY);
    if (token) {
      let user;
      
      
      try {
        user = await lastValueFrom(
          this.http.get<User>(`${this.baseUrl}/auth/profile`, {
            headers: { Authorization: `Token ${token}` },
          })
        );
      } catch (error) {}
      if (localStorage.getItem(ACTIVE_TENANT)) {
        this.setCurrentTenantId(localStorage.getItem(ACTIVE_TENANT)!);
      } else {
        this.setCurrentTenantId(user?.tenants[0].tenantId || '')
      }
      return this.setUser(user);
    }
    return new Promise<{}>((resolve) => resolve({}));
  }


  setCurrentTenantId(tenantId : string){
    this.currentTenantId.set(tenantId);
  }


  // async fetchUser() {
  //   // Always use mockUser, no API call needed
  //   console.warn('Using mock user profile');
  //   this.setCurrentTenantId(mockUser.tenants[0].tenantId)
  //   return this.setUser(mockUser);
  // }



  setUser(user: User | undefined) {
    this.user.set(user);
    this.userExists.set(!!user);
  }

  getDefaultTenant() {
    if (localStorage.getItem(ACTIVE_TENANT)) {
      return this.user()?.tenants.find((tenant) => tenant.tenantId === localStorage.getItem(ACTIVE_TENANT));
    }
    return this.user()?.tenants[0];
  }
}
