import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError, from, lastValueFrom, switchMap, throwError } from 'rxjs';
import { HttpClient, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { AuthResponse, Login, ReAuthentication } from '../../auth/auth.types';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';
import { ACTIVE_TENANT, LOCALSTORAGE_KEY } from '../../../shared/helpers/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly userService = inject(UserService);
  private readonly baseUrl = environment.baseUrl;
  router = inject(Router);

  constructor() {}

  login(credentials: Login) {
    return lastValueFrom(
      this.http.post<AuthResponse>(`${this.baseUrl}/auth/login`, credentials)
    );
  }

  loginWithRefreshToken(reAuthCredentials: ReAuthentication) {
    return lastValueFrom(
      this.http.post<AuthResponse>(
        `${this.baseUrl}/auth/login-with-refresh-token`,
        reAuthCredentials
      )
    );
  }

  refreshAuthentication(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
    reAuthCredentials: ReAuthentication
  ) {
    return from(this.loginWithRefreshToken(reAuthCredentials)).pipe(
      switchMap((res: AuthResponse) => {
        this.saveAuthResponse(res);
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + res.accessToken,
          },
        });
        return next(request);
      }),
      catchError((error) => {
        if (error.status == 403) {
          this.logout();
        }
        return throwError(() => error);
      })
    );
  }

  logout() {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    localStorage.removeItem(ACTIVE_TENANT);
    this.userService.setUser(undefined);
  }

  getToken() {
    return this.getAuthData()?.accessToken;
  }

  getAuthData() {
    if (!localStorage.getItem(LOCALSTORAGE_KEY)) return null;
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)!) as AuthResponse;
  }

  saveAuthResponse(authResponse: AuthResponse) {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(authResponse));
  }
}
